<template>
  <h3>Upload Selection</h3>
  <p>
    Freyda is currently operating as a <b>trial</b> with example documents available.
    Your own documents may be uploaded with an active Freyda subscription. Speak to our team to <a
      href="https://meetings.hubspot.com/danielle155/30-minute-call"
      class="m-ver-l"
    >upgrade</a>.
  </p>
  <div class="vertical-align">
    <div class="tile-icons">
      <p
        v-if="!Object.keys(documentOptionsByDocumentType).length"
        class="loading-area"
      >
        There are currently no available trial documents for this document type.
      </p>
      <div
        v-for="(document) in documentOptionsByDocumentType"
        :key="document.documentRequestId"
        v-tooltip="`Upload ${document.filename}`"
        class="tile-icon"
        :class="{'is-loading': document.isLoading}"
        @click="reUpload(document)"
      >
        <freyda-loader
          v-show="document.isLoading"
          :custom-classes="{'freyda-logo-loader-height-txt-2': true}"
        />
        <div
          v-show="!document.isLoading"
          class="icon-container-static m-hoz-l"
        >
          <file-text-icon
            size="1.75x"
          />
        </div>
        <div class="tile-icon-text">
          {{ document.filename }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FileTextIcon } from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import FreydaLoader from '@/components/general/FreydaLoader.vue';
import Api from '@/store/helpers/api';
import {
  backendToDocumentRequestsInProcessing,
} from '@/store/helpers/request/transformers/transformDocumentRequestsInProcessing';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: { FreydaLoader, FileTextIcon },
  props: {
    documentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentOptions: {},
      documents: [],
      toast: useToast(),
    };
  },
  computed: {
    ...mapGetters({
      documentTypesStoreStatus: 'documentTypes/storeStatus',
    }),
    selectPlaceholder() {
      return this.selectIsReady ? 'Select document' : 'Loading available trial documents...';
    },
    selectIsReady() {
      return this.documentTypesStoreStatus === allowedStates.IS_READY;
    },
    documentOptionsByDocumentType() {
      this.$log.info('current document options:', this.documentOptions);
      return this.documents.filter((option) => option.documentTypeName === this.documentType);
    },
  },
  created() {
    this.getDocuments().then((documents) => {
      this.$log.info('Successfully got document options and settings');
      this.documents = documents;
    });
  },
  methods: {
    ...mapActions({
      addNewUploadedDocument: 'processingDocuments/addNewUploadedDocument',
      getDocumentRequestsInStages: 'processingDocuments/getDocumentRequestsInStages',
    }),
    async getDocuments() {
      this.$log.info('getting document options:');
      const results = await this.getDocumentRequestsInStages(['EXT_PREPARED']);
      this.$log.info('Got results:', results);

      return backendToDocumentRequestsInProcessing(results).map((r) => ({ ...r, isLoading: false }));
    },
    async reUpload(existingDocument) {
      const document = existingDocument;
      if (document.isLoading) {
        this.toast.warning('Document is already being processed');
        return Promise.resolve();
      }
      document.isLoading = true;
      const path = `documentrequest/${document.documentRequestId}/duplicate`;
      this.$log.info('Duplicating pdf: ', path);
      const idToken = this.$store.getters['authenticate/idToken'];

      const minWaitProm = new Promise((r) => setTimeout(r, 1500));
      const postProm = (new Api(process.env, idToken)).post(path, {});
      return Promise.all([postProm, minWaitProm])
        .then(([r]) => {
          this.$log.info('just posted new doc:', r);
          return this.addNewUploadedDocument();
        })
        .catch((error) => {
          this.$log.error(error);
          this.toast.error('Error uploading trial document');
        })
        .finally(() => {
          document.isLoading = false;
        });
    },
  },
};
</script>
