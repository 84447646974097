<template>
  <template v-if="enableUXRefresh || (uploadAreaState === allowedStates.IS_READY)">
    <div
      v-if="documentType === ''"
      class="content"
    >
      <h3 class="upload-section-title">
        Upload
      </h3>
      <div class="loading-area loading-with-logo upload-loading-area-placeholder">
        <p class="upload-section-body-text">
          Select a document type to begin uploading documents
        </p>
      </div>
    </div>
    <div
      v-else-if="showPreparedDocuments"
      class="content"
    >
      <primed-uploads
        :document-type="documentType"
      />
    </div>
    <div
      v-else
      class="content"
    >
      <h3 class="upload-section-title">
        Upload Document
      </h3>
      <p class="upload-section-body-text">
        Each new upload of documents is limited to a total of 100 PDFs (max 500MB)
      </p>
      <s3-upload
        :post-vars="{'docType': documentType}"
      />
    </div>
  </template>
  <div
    v-else-if="uploadAreaState === allowedStates.IS_ERRORING"
    class="content"
  >
    <h3>Upload</h3>
    <p class="error-area">
      An error occurred when loading available document types. Please reload the page to try again
    </p>
  </div>
  <div
    v-else
    class="content"
  >
    <h3>Upload</h3>
    <div class="loading-area loading-with-logo">
      <freyda-loader
        :custom-classes="{'freyda-logo-loader-height-txt-1': true}"
      />
      <p>
        Loading&#8230;
      </p>
    </div>
  </div>
</template>
<script>
import FreydaLoader from '@/components/general/FreydaLoader.vue';
import PrimedUploads from '@/components/uploads/PrimedUploads.vue';
import S3Upload from '@/components/uploads/S3Upload.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: { FreydaLoader, PrimedUploads, S3Upload },
  props: {
    uploadAreaState: {
      type: String,
      default: allowedStates.IS_READY,
    },
    showPreparedDocuments: {
      type: Boolean,
      required: true,
    },
    documentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    allowedStates() {
      return allowedStates;
    },
  },
};
</script>
