<template>
  <div class="freyda-progress-bar-container">
    <div class="freyda-progress-bar">
      <div
        :style="{width: `${percentage}%`, ...backgroundColor !== '' ? { backgroundColor } : {}}"
        class="freyda-progress-level "
      />
    </div>
    <span
      v-if="showPercentage"
      class="progress-percentage"
    >{{ percentage }}%</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    totalValue: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    percentage() {
      return Math.round((this.currentValue / this.totalValue) * 100);
    },
  },
  watch: {
    value(newValue) {
      this.animateProgressBar(newValue);
    },
  },
  methods: {
    /**
     * Animate the progress bar to the specified new value.
     * @param {number} newValue - The new value for the progress bar.
     *                           Should be a number between 0 and 100.
     */
    animateProgressBar(newValue) {
      const animationDuration = 500; // Animation duration in milliseconds
      const startValue = this.currentValue;
      const endValue = newValue;

      let startTime = null;
      const animate = (timestamp) => {
        if (!startTime) {
          startTime = timestamp;
        }

        // Calculate the progress and percentage of the animation.
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / animationDuration, 1);

        // Calculate the current value based on the start and end values and the animation progress.
        const currentValue = Math.round(startValue + (endValue - startValue) * percentage);

        // Update the current value of the progress bar.
        if (progress < animationDuration) {
          this.currentValue = currentValue;
          requestAnimationFrame(animate);
        } else {
          this.currentValue = endValue;
        }
      };

      // Start the animation.
      requestAnimationFrame(animate);
    },
  },
};
</script>
<style lang="scss" scoped>
.freyda-progress-bar-container {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;

  .progress-percentage {
    width: 35px;
    margin-top: 2px;
    color: var(--foreground-_secondary, #999);

    /* Body/Body 2 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.freyda-progress-bar {
  flex: 1;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.freyda-progress-level {
  background-size: 15px 15px;
  border-radius: 10px;
  height: 100%;
  background-color: $col-primary;
  width: 0;
  transition: width 0.5s ease;

  @include gradient-striped;
  @include animation(progress-bar-stripes 2s linear infinite);
  background-size: 15px 15px;
}

</style>
